import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ConfettiExplosion from 'react-confetti-explosion';
import Page from '../components/Page';
import Main from '../../../layouts/Main';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  bio: yup
    .string()
    .trim()
    .max(500, 'Should be less than 500 chars'),
  country: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(80, 'Please enter a valid name')
    .required('Please specify your country name'),
  city: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(80, 'Please enter a valid name')
    .required('Please specify your city name'),
  address: yup
    .string()
    .required('Please specify your address')
    .min(2, 'Please enter a valid address')
    .max(200, 'Please enter a valid address'),
});

const General = ({builder, updateBuilder, FIRE, logOut}) => {
  const initialValues = {
    fullName: '',
    bio: '',
    email: '',
    country: '',
    city: '',
    address: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState('');
  const [amount, setAmount] = useState('');
  const [proof, setProof] = useState(null);
  const [formattedTransactions, setFormattedTransactions] = useState([]);
  const [currentSubView, setCurrentSubView] = useState('addPurchase');
  const [newPurchaseAdded, setNewPurchaseAdded] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Math.random().toString(36));

  const getTransactions = async () => {
    try {
      const transactionsQuery = FIRE.query(FIRE.collection(FIRE.db, "purchases"), FIRE.where("builderId", "==", builder.id));
      const transactionsSnapshot = await FIRE.getDocs(transactionsQuery);
      const formattedTransactionsData = [];
      transactionsSnapshot.forEach((transactionDoc) => {
        const transaction = {id: transactionDoc.id, ...transactionDoc.data()};
        const timestamp = new FIRE.Timestamp(transaction.date.seconds, transaction.date.nanoseconds);
        const transactionDate = timestamp.toDate();

        formattedTransactionsData.push(<Box key={transaction.id}>
            <ListItem alignItems="flex-start" mb="5px">
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText
                primary={transaction.vendor}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.primary"
                    >
                      {moment(transactionDate).format('MMM Do, YYYY')}
                    </Typography>
                    &nbsp;|&nbsp;<span style={{color: 'forestgreen'}}>
                    {<NumericFormat value={parseFloat(transaction.amount).toFixed(2)} thousandSeparator="," prefix="$" displayType="text" />}
                    </span>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        );
      }); 

      setFormattedTransactions(formattedTransactionsData);
    } catch (error) {
      console.error(error.message);
    }
  };

  const createPurchase = async () => {
    setLoading(true);
    if (!vendor || !amount || !proof) {
      setMessage({content: 'All fields required', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return;
    }

    if (amount > 2500) {
      setMessage({content: 'Please contact us for purchases exceeding $2,500', severity: 'warning'});
      setOpen(true);
      setLoading(false);
      return;
    }

    const purchaseRef = await FIRE.addDoc(FIRE.collection(FIRE.db, "purchases"), {
      vendor,
      amount,
      builderId: builder.id,
      date: new Date(),
      proof: ""
    });

    uploadProof(builder.id, purchaseRef.id, purchaseRef);
  }
  
  const uploadProof = (builderId, purchaseId, purchaseRef) => {
    const storage = FIRE.getStorage();
    const proofRef = FIRE.storageRef(storage, `${builderId}${purchaseId}`);

    FIRE.uploadBytes(proofRef, proof)
    .then(async (snapshot) => {
      await FIRE.updateDoc(purchaseRef, {
        proof: snapshot.metadata.fullPath
      })
    })
    .then(async () => {
      // Make more secure...
      const builderRef = FIRE.doc(FIRE.db, "builders", builderId);
      await FIRE.updateDoc(builderRef, {
        'private.purchases.amount': FIRE.increment(parseFloat(amount)),
        'private.purchases.qty': FIRE.increment(1)
      })

      try {
        await updateBuilder();         
      } catch (error) {
        console.error(error.message);
      }

      try {
        await FIRE.updateDoc(FIRE.doc(FIRE.db, 'metrics', 'purchases'), {
          qty: FIRE.increment(1),
          amount: FIRE.increment(parseFloat(amount))
        });          
      } catch (error) {
        console.error(error.message);
      }
    })
    .then(() => {
      setNewPurchaseAdded(true);
      setVendor("");
      setAmount("");
      setProof(null);
      setLoading(false);
      setOpen(true);
      setTimeout(() => {
        setNewPurchaseAdded(false);
      }, 6000);
    })
    .catch((error) => {
      setLoading(false);
      setMessage({content: error.message, severity: 'error'});
      setOpen(true);
    })
  }

  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setTimeout(() => {
      setMessage(null);
    }, 500)
  };

  const fileInputRef = React.useRef();

  return (
    <Main>
      <Page builder={builder} updateBuilder={updateBuilder} getTransactions={getTransactions} setCurrentSubView={setCurrentSubView} logOut={logOut}>
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message ? message.severity : 'success'} sx={{ width: '100%' }}>
            {message ? message.content : 'Purchase add successfully!'}
          </Alert>
        </Snackbar>
        <Grid container>
          
        {currentSubView === 'addPurchase' && <Grid item xs={12} paddingBottom="500px">
          {builder.id !== '7MKzw4OCUjMNG9WBw9652bNXFGj2' && <Typography variant="h6" gutterBottom style={{backgroundColor: '#FFBB18', paddingLeft: '10px'}} fontWeight={700}>
            Uploading Closed
          </Typography>}
          {builder.id !== '7MKzw4OCUjMNG9WBw9652bNXFGj2' && <Typography variant="h6" gutterBottom fontWeight={700}>
            Thank you for participating in The RICE Way challenge for National Black Business Month! 
            Receipts and invoices are under review. Prizes and awards will be announced at our Black 
            Friday event September 8th at <a href="http://russellcenter.org/" target="_blank">The Russell Innovation Center for Entrepreneurs</a>!
          </Typography>}
          <hr />
          {builder.id === '7MKzw4OCUjMNG9WBw9652bNXFGj2' && <Typography variant="h6" gutterBottom fontWeight={700}>
            Add Purchase
          </Typography>}
          {builder.id === '7MKzw4OCUjMNG9WBw9652bNXFGj2' && <Typography variant={'subtitle2'}>
            Please upload proof of purchase so we can verify transaction with vendor. <br />
            <span style={{color: '#fa1839'}}> Paid receipts and invoices only. Mismatched transactions will be removed.</span>
          </Typography>}
          <Box paddingY={4}>
            <Divider />
          </Box>
          {builder.id === '7MKzw4OCUjMNG9WBw9652bNXFGj2' && <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Vendor<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextField
                  label="Where did you shop?"
                  disblaed={loading}
                  variant="outlined"
                  fullWidth
                  value={vendor}
                  onChange={(event) => setVendor(event.target.value)}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Purchase Amount<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextField
                  label="How much did you spend?"
                  disblaed={loading}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Proof of Purchase<span style={{color: 'red'}}> *</span>
                </Typography>
                {!loading && <TextField
                  variant="outlined"
                  disblaed={loading}
                  name={'bio'}
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  fullWidth
                  onChange={(event) => {
                    setProof(event.target.files[0]);
                  }}
                  error={formik.touched.bio && Boolean(formik.errors.bio)}
                  helperText={formik.touched.bio && formik.errors.bio}
                />}
                {newPurchaseAdded && <ConfettiExplosion
                  particleCount={200}
                  particleSize={10}
                  duration={6000}
                />}
                <Button
                  disblaed={loading}
                  onClick={() => createPurchase()}
                  style={{marginTop: '20px', backgroundColor: '#ffbb17', color: '#222', opacity: loading ? 0.5 : 1}} 
                  size={'large'} 
                  variant={'contained'} 
                >
                    {loading && <CircularProgress size="1rem" style={{color: '#222', marginRight: '10px'}} />}{loading ? 'Adding Purchase' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </form>}
        </Grid>}

        {currentSubView === 'myPurchases' && <Grid item xs={12} maxHeight="500px" overflow="auto">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              My Purchases
            </Typography>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              Thanks for supporting Black business the RICE way.
            </Typography>
            <Box paddingY={4}>
              <Divider />
            </Box>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {formattedTransactions.length === 0 && <Typography style={{marginTop: '0px', fontSize: '40px', fontWeight: '200', fontFamily: 'helvetica', marginBottom: '500px'}}>No purchases, yet...</Typography>}
              {formattedTransactions}
            </List>
          </Grid>
        </Grid>}
        </Grid>
      </Page>
    </Main>
  );
};

export default General;
