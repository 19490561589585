import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Button
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../assets/rice_logo.png';
import { NavItem } from './components';

const Topbar = ({ setCurrentView, pages, FIRE, updateBuilder }) => {
  const [authed, setAuthed] = useState(false);
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  useEffect(() => {
    FIRE.auth.onAuthStateChanged(function(user) {
      if (user) {
        setAuthed(true);
      } else {
        setAuthed(false);
      }
    });
  }, [])

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid
        item
        xs={2}
        md={6}
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
      >
        <a href="/">
          <img
            src={logo}
            width="60px"
          />
        </a>
      </Grid>
      <Grid item container xs={10} md={6} justifyContent="flex-end" alignItems="center">
        <Button size="small" onClick={() => setCurrentView({main: 'leaderboard', sub: null})} style={{color: '#222', textTransform: 'none', marginRight: '10px'}}>
          Leaderboard
        </Button>

        {/* <Button size="small" onClick={() => window.open('http://russellcenter.org', '_blank')} style={{color: '#222', cursor: 'pointer', textTransform: 'none', marginRight: '10px'}}>
          About RICE
        </Button> */}

        <Button
          onClick={() => setCurrentView({main: authed ? 'account' : 'auth', sub: null})}
          variant="outlined"
          color="primary"
          style={{color: '#222', borderColor: '#222', marginRight: '10px'}}
          size="small"
        >
          {authed ? 'My Account' : 'Log In'}
        </Button>
      </Grid>
      {/* <Grid sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          // onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Grid> */}
    </Grid>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
