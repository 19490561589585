/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import { 
  useTheme,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Typography,
} from '@mui/material';
import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CountUp from 'react-countup';
import { Animate } from 'react-simple-animate';
import ModalVideo from 'react-modal-video'
import ConfettiExplosion from 'react-confetti-explosion';


const Hero = ({
  setCurrentView,
  FIRE
}) => {
  const [isOpen, setOpen] = useState(false)
  const [counted, setCounted] = useState(false);

  const [transactions, setTransactions] = useState([
    {id: 1, name: 'Evin Taylor', amount: 11.50, vendor: 'Handle Bar ATL', builder: {
      image: require('../../../../assets/evin.avif')
    }},
    {id: 2, name: 'Eldredge Washington', amount: 8.71, vendor: 'Urban Grind Coffee House', builder: {
      image: require('../../../../assets/eldredge.jpeg')
    }},
    {id: 3, name: 'Troy Wilson', amount: 29.98, vendor: 'Dope Pieces Puzzle Company', builder: {
      image: require('../../../../assets/troy.jpeg')
    }},
  ]);
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState(null);

  const getMetrics = async () => {
    const builderMetricsRef = FIRE.doc(FIRE.db, 'metrics', 'builders');
    const purchaseMetricsRef = FIRE.doc(FIRE.db, 'metrics', 'purchases');

    const builderMetricsSnap = await FIRE.getDoc(builderMetricsRef);
    const purchaseMetricsSnap = await FIRE.getDoc(purchaseMetricsRef);

    const builderMetrics = builderMetricsSnap.data();
    const purchaseMetrics = purchaseMetricsSnap.data(); 

    setMetrics({
      builders: builderMetrics,
      purchases: purchaseMetrics,
    });

    setLoading(false);

  };

  useEffect(() => {
    getMetrics();

    setTimeout(() => {
      setCounted(true);
      console.log('CONFETTI')
    }, 2100)
  }, []);



  return (
    <Grid container alignItems="center" spacing={6}>
      <Grid item xs={12} md={6}>
        <Grid item marginBottom={2}>
          <Typography
            fontFamily="helvetica"
            variant="h2"
            color="text.primary"
            sx={{
              fontWeight: 900,
              fontSize: "3rem",
              lineHeight: 1,
              textShadow: "-2px 1px 5px #222",
            }}
          >
            Celebrate National<br />
            Black Business Month<br />
            The <span style={{color: "#fa1839"}}>RICE</span> Way
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom="10px">
          <Typography
            variant="h5"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            mb={0}
            lineHeight={1}
          >
            Build. Black. Business.
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2} mb="20px">
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => {
                setCurrentView({main: 'auth', sub: 'signup'})
              }}
              variant="contained"
              size="large"
              fullWidth
              href=""
              style={{color: '#f5f5f5', backgroundColor: "#fa1839"}}
            >
              SIGN UP
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => window.open('http://russellcenter.org', '_blank')}
              variant="outlined"
              size="large"
              style={{color: '#222', borderColor: '#222'}}
              fullWidth
            >
              About RICE
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} w="100%" h="100%" justifyContent="center" alignItems="center">
        <Typography>Goal Progress</Typography>
        <Typography style={{fontSize: '30px', fontWeight: '900', fontFamily: 'helvetica', color: 'forestgreen'}}>
        <CountUp
          start={0}
          end={parseFloat(metrics?.purchases.amount).toFixed(2)}
          duration={2}
          decimals={2}
          prefix="$"
          separator=","
          style={{fontSize: '30px', fontWeight: '900', fontFamily: 'helvetica', color: 'forestgreen'}}    
        />
        &nbsp;/ $100,000
        {counted && <ConfettiExplosion
            particleCount={200}
            particleSize={10}
            duration={6000}
            style={{marginLeft: '100px'}}
        />}
        </Typography>
        <List sx={{ width: '100%', maxWidth: '500px' }}>
          <Animate play duration={1} start={{ opacity: 0 }} end={{ opacity: 1 }}>
          {transactions.map((transaction) => (<Box key={transaction.id} mb="20px">
            <ListItem alignItems="flex-start" style={{backgroundColor: '#f5f5f5', borderRadius: '50px', paddingLeft: '10px'}}>
              <ListItemAvatar sx={{marginTop: 0, marginRight: '10px'}}>
                <Avatar sx={{ width: 60, height: 60 }} alt="Remy Sharp" src={transaction.builder.image} />
              </ListItemAvatar>
              <ListItemText
                primary={transaction.vendor}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {transaction.name}
                    </Typography>
                    &nbsp;|&nbsp;<span style={{color: 'forestgreen'}}>${parseFloat(transaction.amount).toFixed(2)}</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <MovingOutlinedIcon style={{color: "forestgreen"}} fontSize="large" />
              </ListItemIcon>
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>))}
          </Animate>
        </List>
      </Grid>
      {/* <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex="9999999999999999999999999999999999999999">
        <ModalVideo channel='youtube' height="400px" autoplay isOpen={isOpen} videoId="bviLrvSK3xA" onClose={() => setOpen(false)} />
      </Box> */}
    </Grid>
  );
};

export default Hero;
