import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

const mockLeftGrid = [
  {
    image: require('../../../../../../assets/beautiful_curly_me.png'),
    link: 'https://beautifulcurlyme.com',
    description: 'Website',
    title: 'Beautiful Curly Me',
  },
  {
    image: require('../../../../../../assets/webuyblack.avif'),
    link: 'https://webuyblack.com/',
    description: 'Website',
    title: "WeBuyBlack",
  },
  {
    image: require('../../../../../../assets/dope_pieces.webp'),
    link: 'https://dopepieces.art/',
    description: 'Website',
    title: 'Dope Pieces Puzzle Company',
  },
  {
    image: require('../../../../../../assets/brown_toy_box.png'),
    link: 'https://browntoybox.com/',
    description: 'Website',
    title: 'Brown Toy Box',
  },
  {
    image: require('../../../../../../assets/symphony_chips.webp'),
    link: 'https://symphonychips.com/',
    description: 'Website',
    title: 'Symphony Potato Chips',
  },
];

const mockMiddleGrid = [
  {
    image: require('../../../../../../assets/sublime_donuts.jpeg'),
    link: 'http://www.sublimedonuts.com',
    description: 'Website',
    title: 'Sublime Donuts',
  },
  {
    image: require('../../../../../../assets/urban_grind.jpg'),
    link: 'https://urbangrindatlanta.com/',
    description: 'Website',
    title: 'Urban Grind Coffeehouse',
  },
  {
    image: require('../../../../../../assets/rice_merch.jpeg'),
    link: 'https://tinyurl.com/ricemerch',
    description: 'Website',
    title: "RICE Merch",
  },
  {
    image: require('../../../../../../assets/handle_bar.jpg'),
    link: 'https://handlebar-atl.com/',
    description: 'Website',
    title: 'Handle Bar ATL',
  },
  // {
  //   image: require('../../../../../../assets/jbs_record_lounge.jpeg'),
  //   link: 'https://jbsrecordlounge.com/',
  //   description: 'Website',
  //   title: "JB's Record Lounge",
  // },
];

const mockRightGrid = [
  {
    image: require('../../../../../../assets/stanleys.jpeg'),
    link: 'https://stanleyscigarlounge.com/',
    description: 'Website',
    title: "Stanley's Cigar Lounge",
  },
  {
    image: require('../../../../../../assets/jem.jpg'),
    link: 'https://www.justeldredgemedia.com/',
    description: 'Website',
    title: 'JustEldredge Media',
  },
  {
    image: require('../../../../../../assets/simplicity_beverage.webp'),
    link: 'https://www.simplicitybeverage.com/',
    description: 'Website',
    title: 'Simplicity Beverage Company',
  },
  {
    image: require('../../../../../../assets/polish_dental.webp'),
    link: 'https://www.smilepolish.com/',
    description: 'Website',
    title: 'Polish Dental',
  },
  {
    image: require('../../../../../../assets/hairbrella.webp'),
    link: 'https://www.hairbrella.com/',
    description: 'Website',
    title: 'Hairbrella',
  },
];

const Column = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      {data.map((item, i) => (
        <Box
          key={i}
          sx={{
            marginBottom: { xs: 2, sm: 3 },
            '&:last-child': { marginBottom: 0 },
          }}
        >
          <Box
            boxShadow={1}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              '&:hover': {
                '& img': {
                  transform: 'scale(1.2)',
                },
                '& .portfolio-massonry__main-item': {
                  bottom: 0,
                },
              },
            }}
          >
            <Box
              component={'img'}
              loading="lazy"
              height={1}
              width={1}
              src={item.image}
              alt="..."
              maxHeight={{ xs: 400, sm: 600, md: 1 }}
              sx={{
                transition: 'transform .7s ease !important',
                transform: 'scale(1.0)',
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              }}
            />
            <Box
              position={'absolute'}
              bottom={'-100%'}
              left={0}
              right={0}
              padding={4}
              bgcolor={'background.paper'}
              className={'portfolio-massonry__main-item'}
              sx={{ transition: 'bottom 0.3s ease 0s' }}
            >
              <Box
                component={'svg'}
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 100.1"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  transform: 'translateY(-90%)',
                  zIndex: 2,
                  width: 1,
                }}
              >
                <path
                  fill={theme.palette.background.paper}
                  d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                ></path>
              </Box>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                {item.title}
              </Typography>
              <Button
                onClick={() => window.open(item.link, '_blank')}
                variant='contained'
                style={{background: "#fa1839"}}
              >
                <Typography>{item.description}</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

Column.propTypes = {
  data: PropTypes.array.isRequired,
};

const Main = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color="#fa1839"
        >
          Need some inspiration?
        </Typography>
        <Typography fontWeight={700} variant={'h4'}>
          Try some of these black-owned businesses!
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Column data={mockLeftGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockMiddleGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockRightGrid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
