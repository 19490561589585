import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import {
  CircularProgress
} from '@mui/material';


const Leaders = ({ FIRE, totalBuilders }) => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState([]);
  const [visibleLeaders, setVisibleLeaders] = useState([]);
  const [paginationIncrement, setPaginationIncrement] = useState(12);
  const [paginationPosition, setPaginationPosition] = useState(0);

  const getLeaders = async () => {
    setLoading(true);
    const buildersRef = FIRE.collection(FIRE.db, 'builders');
    const leadersQuery = FIRE.query(buildersRef, FIRE.orderBy("private.purchases.amount", "desc"));
    // const leadersPaginatedQuery = FIRE.query(buildersRef, 
    //   FIRE.orderBy("private.purchases.amount", "desc"), 
    //   FIRE.startAfter(paginationPosition), 
    //   FIRE.limit(paginationIncrement)
    // );
    const leadersSnapshot = await FIRE.getDocs(leadersQuery);
    const leaderDocs = [];
    let leaderRank = 0;
    leadersSnapshot.forEach((leaderDoc) => {
      leaderRank++;
      leaderDocs.push({rank: leaderRank, ...leaderDoc.data()});
    })

    setLeaders(leaderDocs);

    getVisibleLeaders('initial', leaderDocs);

  };

  const getVisibleLeaders = (direction, allLeaders = leaders) => {
    if (direction === 'prev' && (paginationPosition - paginationIncrement) < 0) {
      setLoading(false);
      return;
    } else if (direction === 'next' && (paginationPosition + (paginationIncrement)) >= allLeaders.length) {
      setLoading(false);
      return;
    }

    setLoading(true);

    let updatedVisibleLeaders = null;
    
    if (direction === 'next') {
      const paginationStart = paginationPosition + paginationIncrement;
      const paginationEnd = paginationStart + paginationIncrement;
      updatedVisibleLeaders = allLeaders.slice(paginationStart, paginationEnd);
      setPaginationPosition(paginationStart);
      setVisibleLeaders(updatedVisibleLeaders);
    } else if (direction === 'prev') {
      const paginationStart = paginationPosition - paginationIncrement;
      const paginationEnd = paginationStart + paginationIncrement;
      updatedVisibleLeaders = allLeaders.slice(paginationStart, paginationEnd);
      setPaginationPosition(paginationStart);
      setVisibleLeaders(updatedVisibleLeaders);
    } else {
      updatedVisibleLeaders = allLeaders.slice(paginationPosition, paginationIncrement);
      setVisibleLeaders(updatedVisibleLeaders);
    }

    setLoading(false);
  }

  const theme = useTheme();

  useEffect(() => {
    getLeaders();
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        {loading && <Grid item xs={12} sx={{minHeight: '300px'}} textAlign="center" alignItems="center" justifyContent="center">
          <CircularProgress size="100px" sx={{color: '#222', marginTop: '100px'}} />
        </Grid>}
        {!loading && visibleLeaders.map((leader, i) => (
          <Grid item xs={12} sm={6} md={3} key={leader.id}>
            <Box
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={leader.image}
                  title={leader.firstName}
                  sx={{
                    height: { xs: 250, md: 250 },
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
                <Box component={CardContent} style={{backgroundColor: '#ffbb17'}}>
                  <Typography variant={'h6'} style={{
                    fontFamily: 'helvetica',
                    fontWeight: '900',
                    fontSize: '20px',
                    lineHeight: 1,
                  }} mb={0} gutterBottom>
                    {leader.rank}. {leader.firstName} {leader.lastName[0]}.
                  </Typography>
                  <Typography variant={'body2'} style={{fontSize: '14px'}} color="text.secondary">
                    <span style={{fontWeight: '700'}}>Purchases:</span> <span style={{color: 'forestgreen'}}>{<NumericFormat value={parseFloat(leader.private.purchases.amount).toFixed(2)} thousandSeparator="," prefix="$" displayType="text" />}</span> <span style={{fontWeight: '200', fontFamily: 'helvetica'}}>({leader.private.purchases.qty})</span>
                  </Typography>
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item container xs={12} md={12} spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={6} md={2}>
            <Button onClick={() => getVisibleLeaders('prev')} variant="contained" sx={{backgroundColor: "#222"}} fullWidth>{'<< prev'}</Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button onClick={() => getVisibleLeaders('next')} variant="contained" sx={{backgroundColor: "#222"}} fullWidth>{'next >>'}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Leaders;
