import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';
import {
  Button,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from '../../../../components/Container';
import Leaders from '../Leaders';

const pages = [
  {
    title: 'Add Purchase',
    cta: true,
    subView: 'addPurchase'
  },
  {
    title: 'My Purchases',
    cta: false,
    subView: 'myPurchases'
  },
  {
    title: 'Log Out',
    cta: false,
    subView: ''
  }
];

const Page = ({ FIRE }) => {
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState(null);

  const getMetrics = async () => {
    const builderMetricsRef = FIRE.doc(FIRE.db, 'metrics', 'builders');
    const purchaseMetricsRef = FIRE.doc(FIRE.db, 'metrics', 'purchases');

    const builderMetricsSnap = await FIRE.getDoc(builderMetricsRef);
    const purchaseMetricsSnap = await FIRE.getDoc(purchaseMetricsRef);

    const builderMetrics = builderMetricsSnap.data();
    const purchaseMetrics = purchaseMetricsSnap.data(); 

    setMetrics({
      builders: builderMetrics,
      purchases: purchaseMetrics,
    });

    setLoading(false);

  };

  useEffect(() => {
    getMetrics()
  }, []);

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item container xs={12} bgcolor={'#222'} paddingY={4}>
        <Grid item xs={12} paddingX="40px" paddingTop="40px" paddingBottom="60px">
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize="40px"
            gutterBottom
            sx={{ color: '#f5f5f5' }}
            mb={0}
          >
            Leaderboard
          </Typography>
          <Typography variant="h6" sx={{ fontFamily: 'helvetica', fontWeight: '200', color: '#f5f5f5' }}>
            Top builders so far.
          </Typography>
          <Box>
            <Typography
              fontFamily="helvetica"
              fontWeight={700}
              fontSize="20px"
              color="#f5f5f5"
            >
              <span style={{fontWeight: "700", color: "#ffbb17"}}>Builders:</span> <span style={{fontWeight: "200"}}>{metrics?.builders.qty}</span> &nbsp; <span style={{fontWeight: "700", color: "#ffbb17"}}>Purchases:</span> <span style={{fontWeight: "200"}}>{<NumericFormat value={parseFloat(metrics?.purchases.amount).toFixed(2)} thousandSeparator="," prefix="$" displayType="text" />} ({metrics?.purchases.qty})</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={-8} marginX="40px" mb='100px'>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>
              <Leaders FIRE={FIRE} totalBuilders={metrics?.builders.qty} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
