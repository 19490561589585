import React from 'react';
import {
  Grid,
  Box,
  Divider
} from '@mui/material';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
  Hero,
  Browsers,
  Businesses,
  Subscription,
  VideoSection,
} from './components';

const Landing = ({setCurrentView, FIRE}) => {
  return (
    <Main setCurrentView={setCurrentView}>
      <Grid container bgcolor={'#FFBB18'} marginTop={-13} paddingTop={13}>
        <Container>
          <Hero FIRE={FIRE} setCurrentView={setCurrentView} />
        </Container>
        <Container maxWidth={1} padding={'0'}>
          <VideoSection />
        </Container>
        <Container style={{paddingTop: 0, paddingBottom: 0}}>
          <Businesses />
        </Container>
        <Container style={{paddingTop: '40px', paddingBottom: '40px'}}>
          <Divider />
        </Container>
        <Container paddingTop={'0 !important'}>
          <Subscription setCurrentView={setCurrentView} />
        </Container>
      </Grid>
    </Main>
  );
};

export default Landing;
