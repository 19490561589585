import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Button,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from '../../../../components/Container';


const Page = ({ children, builder, updateBuilder, getTransactions, setCurrentSubView, logOut }) => {
  const [activeLink, setActiveLink] = useState('');
  
  useEffect(() => {
    if (builder === {}) {
      updateBuilder();
    }
  }, []);

  const theme = useTheme();

  const pages = [
    {
      title: 'Add Purchase',
      cta: true,
      subView: 'addPurchase',
      func: () => {
        setCurrentSubView('addPurchase');
      }
    },
    {
      title: 'My Purchases',
      cta: false,
      subView: 'myPurchases',
      func: () => {
        getTransactions();
        setCurrentSubView('myPurchases');
      }
    },
    {
      title: 'Log Out',
      cta: false,
      subView: '',
      func: () => {
        logOut();
      }
    }
  ];

  return (
    <Grid container>
      <Grid item container bgcolor={'#222'} paddingY={4} paddingX="20px">
        <Container>
          <Grid item container alignItems="center">
            <Grid item>
              <Avatar
                alt={`${builder.firstName} ${builder.lastName}`} 
                src={builder.image}
                sx={{ width: '140px', height: '140px', marginRight: '20px' }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight={700}
                gutterBottom
                sx={{ color: '#f5f5f5' }}
                mb={0}
              >
                Hey, {builder?.firstName}!
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: 'helvetica', fontWeight: '200', color: '#f5f5f5' }}>
                Thanks for building Black business.
              </Typography>
              <Box marginBottom="10px">
                <Typography
                  fontFamily="helvetica"
                  fontWeight={700}
                  color="#f5f5f5"
                >
                  <span style={{fontWeight: "700", color: "#ffbb17"}}>Purchases:</span> ${parseFloat(builder?.private?.purchases.amount).toFixed(2)} ({builder?.private?.purchases.qty})
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Container paddingTop={'0 !important'} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item container xs={12} md={3}>
            <Card sx={{ boxShadow: 3, padding: '20px' }}>
              {pages.map((item) => (
                <Button
                  key={item.title}
                  style={{ backgroundColor: item.cta ? "#fa1839" : "#222", marginBottom: '10px'}} 
                  size={'medium'} 
                  variant={'contained'}
                  onClick={() => item.func()}
                  fullWidth
                >
                  {item.title}
                </Button>
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};


export default Page;
