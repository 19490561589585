import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            // component="a"
            href="/"
            title="theFront"
            width={80}
          >
            <Typography
              align={'center'}
              variant={'subtitle2'}
              color="#f5f5f5"
              mr={5}
              gutterBottom
            >
              Terms  
            </Typography>  
            <Typography
              align={'center'}
              variant={'subtitle2'}
              color="#f5f5f5"
              gutterBottom
            >
              Privacy  
            </Typography>            
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Typography
              align={'center'}
              variant={'subtitle2'}
              color="#f5f5f5"
              gutterBottom
            >
              &copy; {moment().format('YYYY')} Russell Innovation Center for Entrepreneurs &middot; All rights reserved
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
