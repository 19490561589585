import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { Form } from './components';


const Auth = ({
  FIRE,
  setCurrentView,
  currentView,
  authSubView,
  getAuth,
  createUserWithEmailAndPassword,
  authenticate
}) => {
  
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const auth = getAuth();

  const login = async (email, password) => {
    if (!email || !password) {
      setMessage({content: 'All fields required', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }

    try {
      const userCredential = await FIRE.signInWithEmailAndPassword(auth, email, password)
      const builderId = userCredential.user.uid;
      const builderRef = FIRE.doc(FIRE.db, "builders", builderId);
      const builderSnap = await FIRE.getDoc(builderRef);
      const builder = builderSnap.data();
      authenticate(builder);
    } catch (error) {
      setMessage({content: error.code, severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const handleClose = (event, reason) => {
  
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setTimeout(() => {
      setMessage(null);
    }, 500)
  };

  const uploadProfileImage = (builderId, image) => {
    const storage = FIRE.getStorage();
    const profileImageRef = FIRE.storageRef(storage, `profileImages/${builderId}`);
    const builderRef = FIRE.doc(FIRE.db, "builders", builderId);

    FIRE.uploadBytes(profileImageRef, image)
    .then(async (snapshot) => {
      const imageUrl = await FIRE.getDownloadURL(FIRE.storageRef(FIRE.getStorage(), `profileImages/${builderId}`));

      await FIRE.updateDoc(builderRef, {
        image: imageUrl
      })
    })
    .catch((error) => {
      setMessage({content: error.code, severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    })
  }

  const createUser = async (firstName, lastName, email, confirmEmail, phone, confirmPhone, password, confirmPassword, image) => {
    if (!firstName, !lastName, !email, !confirmEmail, !phone, !confirmPhone, !password, !confirmPassword, !image) {
      setMessage({content: 'All fields required', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return;
    }

    if (email !== confirmEmail) {
      setMessage({content: 'Email mismatch', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }

    if (phone !== confirmPhone) {
      setMessage({content: 'Phone mismatch', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }

    if (password !== confirmPassword) {
      setMessage({content: 'Password mismatch', severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const builderId = userCredential.user.uid;
      await FIRE.setDoc(FIRE.doc(FIRE.db, 'builders', builderId), {
        firstName,
        lastName,
        email,
        phone,
        private: {
          purchases: {
            qty: 0,
            amount: 0,
          },
        },
        id: builderId
      });
  
      await uploadProfileImage(builderId, image)
  
  
      await FIRE.updateDoc(FIRE.doc(FIRE.db, 'metrics', 'builders'), {
        qty: FIRE.increment(1)
      });          
  
      authenticate({id: builderId, firstName, lastName, email, phone});
    } catch (error) {
      setMessage({content: error.code, severity: 'error'});
      setOpen(true);
      setLoading(false);
      return; 
    }
  }

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      >
        <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message ? message.severity : 'success'} sx={{ width: '100%' }}>
            {message ? message.content : 'Purchase add successfully!'}
          </Alert>
        </Snackbar>
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            position={'relative'}
          >
            <Box
              width={1}
              order={{ xs: 2, md: 1 }}
              display={'flex'}
              alignItems={'center'}
            >
              <Container>
                <Form login={login} createUser={createUser} setCurrentView={setCurrentView} currentView={currentView} />
              </Container>
            </Box>
            <Box
              sx={{
                flex: { xs: '0 0 100%', md: '0 0 50%' },
                position: 'relative',
                maxWidth: { xs: '100%', md: '50%' },
                order: { xs: 1, md: 2 },
                minHeight: { xs: 'auto', md: 'calc(100vh - 58px)' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50vw' },
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      left: '0%',
                      width: 1,
                      height: 1,
                      position: { xs: 'relative', md: 'absolute' },
                      clipPath: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                      shapeOutside: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: 'auto', md: 1 },
                        '& img': {
                          objectFit: 'cover',
                        },
                      }}
                    >
                      <Box
                        component={'img'}
                        loading="lazy"
                        src={
                          require('../../assets/nourish_bloom.jpeg')
                        }
                        height={{ xs: 'auto', md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          filter:
                            theme.palette.mode === 'dark'
                              ? 'brightness(0.7)'
                              : 'none',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default Auth;
