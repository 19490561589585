import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

const mock = [
  {
    title: "JB's Record Lounge",
    subtitle:
      '.......',
    icon: require('../../../../assets/jbs_record_logo.jpeg'),
  },
  {
    title: 'Dope Puzzle Pieces',
    subtitle:
      '.......',
    icon: require('../../../../assets/dope_puzzle_logo.webp'),
  },
  {
    title: 'The Village Market',
    subtitle:
      '.......',
    icon: require('../../../../assets/village_market_logo.webp'),
  },
  {
    title: 'Urban Grind Coffeehouse',
    subtitle:
      '.......',
    icon: require('../../../../assets/urban_grind_logo.webp'),
  },
];

const Browsers = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color="#fa1839"
        >
          Need some inspiration?
        </Typography>
        <Typography fontWeight={700} variant={'h4'}>
          Try some of these black-owned businesses!
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={3} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={240}
                  height={240}
                  marginBottom={2}
                  src={item.icon}
                />
                <Typography
                  variant={'h6'}
                  gutterBottom
                  fontWeight={500}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Browsers;
