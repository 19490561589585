import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Page from '../components/Page';
import Main from '../../../layouts/Main';

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  bio: yup
    .string()
    .trim()
    .max(500, 'Should be less than 500 chars'),
  country: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(80, 'Please enter a valid name')
    .required('Please specify your country name'),
  city: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(80, 'Please enter a valid name')
    .required('Please specify your city name'),
  address: yup
    .string()
    .required('Please specify your address')
    .min(2, 'Please enter a valid address')
    .max(200, 'Please enter a valid address'),
});

const Leaderboard = ({ FIRE }) => {
  const initialValues = {
    fullName: '',
    bio: '',
    email: '',
    country: '',
    city: '',
    address: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const [currentSubView, setCurrentSubView] = useState('addPurchase');
  const [transactions, setTransactions] = useState([
    {vendor: 'Urban Grind Coffeehouse', amount: 22.17, date: new Date()},
    {vendor: 'Dope Puzzle Pieces Company', amount: 81.25, date: new Date()},
    {vendor: "JB's Record Lounge", amount: 50.00, date: new Date()},
    {vendor: 'Nourish + Bloom', amount: 326.00, date: new Date()},
    {vendor: 'The Village Market', amount: 85.50, date: new Date()},
    {vendor: 'God is Dope', amount: 30.25, date: new Date()},
    {vendor: 'The James Room', amount: 608.10, date: new Date()},
    {vendor: 'Urban Grind Coffeehouse', amount: 22.17, date: new Date()},
    {vendor: 'Dope Puzzle Pieces Company', amount: 81.25, date: new Date()},
    {vendor: "JB's Record Lounge", amount: 50.00, date: new Date()},
    {vendor: 'Nourish + Bloom', amount: 326.00, date: new Date()},
    {vendor: 'The Village Market', amount: 85.50, date: new Date()},
    {vendor: 'God is Dope', amount: 30.25, date: new Date()},
    {vendor: 'The James Room', amount: 608.10, date: new Date()},
  ]);

  return (
    <Main>
      <Page FIRE={FIRE}>
        {currentSubView === 'addPurchase' && <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Add Purchase
          </Typography>
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            Please upload proof of purchase such as a receipt or paid invoice
            so we can verify transaction with vendor.
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Vendor<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextField
                  label="Where did you shop?"
                  variant="outlined"
                  fullWidth
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Purchase Amount<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextField
                  label="How much did you spend?"
                  variant="outlined"
                  name={'email'}
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Proof of Purchase<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextField
                  variant="outlined"
                  name={'bio'}
                  type="file"
                  fullWidth
                  value={null}
                  onChange={formik.handleChange}
                  error={formik.touched.bio && Boolean(formik.errors.bio)}
                  helperText={formik.touched.bio && formik.errors.bio}
                />
                <Button style={{marginTop: '20px', backgroundColor: '#ffbb17', color: '#222'}} size={'large'} variant={'contained'} type={'submit'}>
                    Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>}

        {currentSubView === 'myPurchases' && <Box maxHeight="500px" overflow="auto">
          <Typography variant="h6" gutterBottom fontWeight={700}>
            My Purchases
          </Typography>
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            Thanks for supporting Black business the RICE way.
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {transactions.map((transaction) => (<Box key={transaction.id}>
              <ListItem alignItems="flex-start" mb="5px">
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText
                  primary={transaction.vendor}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="caption"
                        color="text.primary"
                      >
                        {moment(transaction.date).format('MMMM DD, YYYY')}
                      </Typography>
                      &nbsp;|&nbsp;<span style={{color: 'forestgreen'}}>${transaction.amount}</span>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>))}
          </List>
        </Box>}
      </Page>
    </Main>
  );
};

export default Leaderboard;
