import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ModalVideo from 'react-modal-video'


const VideoSection = () => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false)

  return (
    <Box
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: '25px',
        height: 0,
      }}
      // sx={{
      //   position: 'relative',
      //   display: 'flex',
      //   alignItems: 'center',
      //   '&::after': {
      //     content: '""',
      //     position: 'absolute',
      //     left: 0,
      //     right: 0,
      //     bottom: 0,
      //     top: 0,
      //     width: 1,
      //     height: 1,
      //     zIndex: 3,
      //     background: theme.palette.primary.main,
      //     opacity: 0.2,
      //   },
      // }}
    >
      {/* <Box position="absolute" top={0} left={0}>
        <img width={500} height={500} src="../../../play.png" />
      </Box> */}
      {/* <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        backgroundColor="rgba(0,0,0,0.75)"
      /> */}
      {/* <Box width="100%" height="400px">
        <LiteYouTubeEmbed 
            id="bviLrvSK3xA"
            title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
        />
      </Box> */}
      {/* <Box
        component={'video'}
        width={1}
        autoPlay={true}
        muted={true}
        loop={true}
      >
        <source
          src={require("../../../../assets/rice_way_video.mp4")}
          type="video/mp4"
        />
        <source
          src={require("../../../../assets/rice_way_video.mp4")}
          type="video/webm"
        />
        <source
          src={require("../../../../assets/rice_way_video.mp4")}
          type="video/ogg"
        />
        Your browser do not support HTML5 video.
      </Box> */}
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }} 
      src="https://www.youtube.com/embed/bviLrvSK3xA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </Box>
  );
};

export default VideoSection;
