/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';


const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const Form = ({login, createUser, setCurrentView, currentView}) => {
  const [loading, setLoading] = useState(false);
  const [authType, setAuthType] = useState('login');
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [securePassword, setSecurePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color="#"
        >
          {authType === 'login' && currentView?.sub !== 'signup' ? 'Login' : 'Sign Up'}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
          color="#fa1839"
        >
          Welcome{authType === 'login' && !currentView?.sub && ' back'}
        </Typography>
        {(authType === 'login' && currentView.sub !== 'signup') && <Typography color="text.secondary">
          Login to manage your account.
        </Typography>}
        {(authType === 'onboarding' || currentView?.sub === 'signup') && <Typography color="text.secondary">
          Create an account to build black business.
        </Typography>}
      </Box>
      <form>
        <Grid container spacing={2}>
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <Typography color="#777">Add an image of yourself:</Typography>
            <TextField
              variant="outlined"
              disabled={loading}
              type="file"
              inputProps={{ accept: 'image/*' }}
              style={{marginTop: '5px'}}
              size="small"
              fullWidth
              onChange={(event) => {
                setImage(event.target.files[0]);
              }}
              error={formik.touched.bio && Boolean(formik.errors.bio)}
              helperText={formik.touched.bio && formik.errors.bio}
            />  
            <Divider style={{marginTop: '20px'}} />
          </Grid>}
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <TextField
              label="First Name *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>}
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <TextField
              label="Last Name *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>}
          <Grid item xs={12}>
            <TextField
              label="Email Address*"
              variant="outlined"
              disabled={loading}
              name={'email'}
              fullWidth
              size="small"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <TextField
              label="Confirm Email*"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              value={confirmEmail}
              onChange={(event) => { setConfirmEmail(event.target.value) }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>}
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <TextField
              label="Mobile Number *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              type="number"
              value={mobilePhone}
              onChange={(event) => setMobilePhone(event.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>}
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <TextField
              label="Confirm Number *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              type="number"
              value={confirmPhone}
              onChange={(event) => setConfirmPhone(event.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
              </Box>
            </Box>
            <TextField
              label="Secure Password *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              type="password"
              value={securePassword}
              onChange={(event) => setSecurePassword(event.target.value)}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          {(authType === 'onboarding' || currentView?.sub === 'signup') && <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
              </Box>
            </Box>
            <TextField
              label="Confirm Password *"
              variant="outlined"
              disabled={loading}
              fullWidth
              size="small"
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>}
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              {(authType === 'login' && currentView.sub !== 'signup') && <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Don't have an account yet?{' '}
                  <Link
                    color={'primary'}
                    underline={'none'}
                    onClick={() => {
                      setAuthType("onboarding");
                      setCurrentView({main: 'auth', sub: ''});
                    }}
                  >
                    Sign up here.
                  </Link>
                </Typography>
              </Box>}
              {(authType === 'onboarding' || currentView?.sub === 'signup') && <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Already have an account?{' '}
                  <Link
                    color={'primary'}
                    underline={'none'}
                    onClick={() => {
                      setAuthType("login");
                      setCurrentView({main: 'auth', sub: ''})
                    }}
                  >
                    Log in here.
                  </Link>
                </Typography>
              </Box>}
              {(authType === 'login' && currentView.sub !== 'signup') && <Button 
                onClick={() => {
                  login(email, securePassword);
                }} 
                disabled={loading}
                style={{ backgroundColor: "#fa1839"}} 
                size={'large'} 
                variant={'contained'}
              >
                {loading && <CircularProgress size="1rem" style={{color: 'white', marginRight: '10px'}} />}Log In
              </Button>}
              {(authType === 'onboarding' || currentView?.sub === 'signup') && <Button 
                onClick={() => {
                    createUser(firstName, lastName, email, confirmEmail, mobilePhone, confirmPhone, securePassword, confirmPassword, image)
                }}
                style={{ backgroundColor: "#fa1839"}} 
                disabled={loading}
                size={'large'} 
                variant={'contained'}
              >
                {loading && <CircularProgress size="1rem" style={{color: 'white', marginRight: '10px'}} />}Sign Up
              </Button>}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
