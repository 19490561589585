import { Fragment, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  query,
  where,
  doc,
  collection,
  setDoc,
  getDoc,
  addDoc,
  updateDoc,
  getDocs,
  limit,
  orderBy,
  Timestamp,
  increment,
  startAfter
} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { 
  Box,
  AppBar,
  useMediaQuery,
  useScrollTrigger
} from '@mui/material';
import Landing from './views/Landing';
import Auth from './views/Auth';
import { General } from './views/Account';
import { Leaderboard } from './views/Leaderboard';
import Topbar from './layouts/Main/components/Topbar';
import Container from './components/Container';
import { useTheme } from '@mui/material/styles';
import {
  Grid
} from '@mui/material';


const firebaseConfig = {
  apiKey: "AIzaSyBp3p2MiTDgQja6dgi1x4-lV9uaowzKhJU",
  authDomain: "the-rice-way.firebaseapp.com",
  projectId: "the-rice-way",
  storageBucket: "the-rice-way.appspot.com",
  messagingSenderId: "855625562205",
  appId: "1:855625562205:web:9b04e81bbb707d020935b4",
  measurementId: "G-JQJVTXNLKK"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const FIRE = {
  app: app,
  db: db,
  auth: getAuth(),
  signOut: signOut,
  getFirestore: getFirestore,
  analytics: analytics,
  query: query,
  limit: limit,
  orderBy: orderBy,
  where: where,
  doc: doc,
  collection: collection,
  addDoc: addDoc,
  setDoc: setDoc,
  getDoc: getDoc,
  updateDoc: updateDoc,
  getDocs: getDocs,
  signInWithEmailAndPassword: signInWithEmailAndPassword,
  getStorage: getStorage,
  storageRef: ref,
  uploadBytes: uploadBytes,
  getDownloadURL: getDownloadURL,
  Timestamp: Timestamp,
  increment,
  startAfter
}


function App() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const [builder, setBuilder] = useState({});
  const [currentView, setCurrentView] = useState({main: 'landing', sub: null});
  const [authSubView, setAuthSubView] = useState(null);

  const authenticate = (builder) => {
    updateBuilder(builder.id);
    setCurrentView({main: 'account', sub: null});
  }
  
  const logOut = async () => {
    await FIRE.signOut(FIRE.auth)
    setBuilder(null);
    setCurrentView({main: 'landing', sub: null});
  }

  const updateBuilder = async (id) => {
    if (!id) {
      id = builder.id
    }

    const builderRef = FIRE.doc(FIRE.db, 'builders', id);
    const builderSnap = await FIRE.getDoc(builderRef);

    setBuilder(builderSnap.data());
  }

  useEffect(() => {
    FIRE.auth.onAuthStateChanged(function(user) {
      if (user) {
        updateBuilder(user.uid);
      } else {
      }
    });
  }, [])

  return (
    <Fragment>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          // backgroundColor: trigger ? theme.palette.background.paper : '#FFBB18',
          backgroundColor: "#FFBB18"
        }}
        elevation={trigger ? 1 : 0}
      >
        <Grid paddingY={1}>
          <Topbar
            setCurrentView={setCurrentView}
            // onSidebarOpen={handleSidebarOpen}
            pages={{}}
            FIRE={FIRE}
            updateBuilder={updateBuilder}
            colorInvert={false}
          />
        </Grid>
      </AppBar>
      {currentView.main === 'landing' && <Landing setCurrentView={setCurrentView} FIRE={FIRE} />}
      {currentView.main === 'auth' && <Auth currentView={currentView} setCurrentView={setCurrentView} authSubView={authSubView} FIRE={FIRE} authenticate={authenticate} getAuth={getAuth} createUserWithEmailAndPassword={createUserWithEmailAndPassword} />}
      {currentView.main === 'account' && <General builder={builder} updateBuilder={updateBuilder} FIRE={FIRE} setCurrentView={setCurrentView} logOut={logOut} />}
      {currentView.main === 'leaderboard' && <Leaderboard FIRE={FIRE} setCurrentView={setCurrentView} />}
    </Fragment>
  );
}

export default App;
